import React from 'react'
import './UserNotification.scss';
import notifIcon from "../../../../assets/icons/abell.svg"


export default function UserNotification({toggleNews}) {
    return (
        <>
        <div onClick={
            () => toggleNews(true)
            } id="user-notification">
            <img id="notification-icon" src={notifIcon} alt="notification" title="What's new"></img>
        </div>
        </>
    )
}
