import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import "./Step1.scss";

export default function Step1() {
  const stepData = useStoreState(state => state.stepData);
  const setModalStep = useStoreActions(actions => actions.setModalStep);
  const addNewProject = useStoreActions(actions => actions.addProject);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Project Name")
      .test('no-forbidden-chars', `The project name cannot contain /, ? or & characters`, (value) => {
        return !/[/?&]/.test(value);
      })
      .typeError("Must be a string")
  });

  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    initialValues: {
      name: ""
    },
    validationSchema,
    onSubmit(data) {
      addNewProject(data);
      setModalStep(["1b"]);
    },
    enableReinitialize: true
  });
  return (
    <div id="step-1">
      <div className="modal-title" style={stepData ? {} : {borderBottom: "solid 1px lightgray"}}>Informations</div>
      <form onSubmit={handleSubmit}>
        <TextField
          className="input-field"
          variant="outlined"
          type="text"
          name="name"
          label={(touched.name && errors.name) || "Project Name"}
          error={touched.name && errors.name}
          onChange={handleChange}
          value={values.name}
        />
        <div className="submit-btn-container">
          <button className="next-button" type="submit">
            Create Project
          </button>
        </div>
      </form>
    </div>
  );
}
