import React from "react";
import { useStoreActions } from "easy-peasy";
import "./UserLogout.scss";
import logoutIcon from "../../../../assets/icons/logout.svg";
import { Auth } from "aws-amplify";

export default function UserLogout() {
  const authUser = useStoreActions(actions => actions.setAuthState);
  const signOut = () => {
    Auth.signOut()
      .then(() => {
        authUser(false);
      })
      .catch(err => console.log(err));
  };
  return (
    <div onClick={signOut} className="user-logout">
      <img src={logoutIcon} alt="logout" title="Logout" />
    </div>
  );
}
