import React, { useState, useEffect } from "react";
import { useStoreActions } from "easy-peasy";
import { Formik } from "formik";
import * as Yup from "yup";
import tricerLogo from "../../assets/icons/logo.png";
import homeLogo from "../../assets/icons/home.svg";
import { TextField } from "@material-ui/core";
import "./AuthComp.scss";
import { useSnackbar } from "notistack";
import { Amplify } from "aws-amplify";

export default function AuthComp() {
  const { enqueueSnackbar } = useSnackbar();
  const [authScreen, setAuthScreen] = useState("login");
  const [currentUser, setCurrentUser] = useState(null);
  const [currentEmail, setCurrentEmail] = useState(null);

  // setUserIsLoggedIn accepts true or false depending on logged in status
  const setUserIsLoggedIn = useStoreActions((actions) => actions.setAuthState);
  const setUser = useStoreActions((actions) => actions.setUser);
  const setLoadingState = useStoreActions((actions) => actions.setIsLoading);
  const setModalStep = useStoreActions((actions) => actions.setModalStep);

  useEffect(() => {
    Amplify.Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user.username);
        setUserIsLoggedIn(true);
        setLoadingState(false);
      })
      .catch((err) => {
        if (err !== "not authenticated") {
          console.log(err);
        }
        setUserIsLoggedIn(false);
        setLoadingState(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authScreen]);

  // add
  if (authScreen === "login") {
    return (
      <>
        <a
          className="tricer-website-link top-left-clipped"
          href="https://tractebel-engie.com/en/tricer"
        >
          <div className="website-text">Access official website</div>{" "}
          <img id="home-icon" src={homeLogo} alt="home" />
        </a>

        <div className="auth">
          <div className="auth-form-container-login">
            <div className="auth-title-container">
              <img
                className="tricer-logo"
                src={tricerLogo}
                alt="logo icefall"
              />
            </div>
            <Formik
              key="form1"
              initialValues={{
                username: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string()
                  .email("Valid email required")
                  .required("Username required")
                  .typeError("Invalid username or email"),
                password: Yup.string()
                  .required("Password required")
                  .typeError("Must be a valid password"),
              })}
              onSubmit={(values) => {
                // Used to hide or show website button
                // document.getElementsByClassName("top-left-clipped")[0].style.zIndex = "-1";
                setCurrentEmail(values.username);
                setLoadingState(true);
                Amplify.Auth.signIn(values)
                  .then((user) => {
                    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
                      setCurrentUser(user);
                      setAuthScreen("new_password");
                      setLoadingState(false);
                      // Used to hide or show website button
                      // document.getElementsByClassName("top-left-clipped")[0].style.zIndex = "2";
                    } else {
                      setUser(user.username);
                      setModalStep([null]);
                      setUserIsLoggedIn(true);
                      setLoadingState(false);
                      // Used to hide or show website button
                      // document.getElementsByClassName("top-left-clipped")[0].style.zIndex = "2";
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    enqueueSnackbar(err.message, {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    });
                    setLoadingState(false);
                    // Used to hide or show website button
                    document.getElementsByClassName(
                      "top-left-clipped"
                    )[0].style.zIndex = "2";
                  });
              }}
            >
              {({ touched, errors, values, handleChange, handleSubmit }) => (
                <form key="login-form" onSubmit={handleSubmit}>
                  <TextField
                    className="input-field"
                    type="text"
                    name="username"
                    label={(touched.username && errors.username) || "Email"}
                    error={touched.username && errors.username !== undefined}
                    autoComplete="username"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.username}
                  />
                  <TextField
                    className="input-field"
                    type="password"
                    name="password"
                    label={(touched.password && errors.password) || "Password"}
                    error={touched.password && errors.password !== undefined}
                    autoComplete="current-password"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.password}
                  />
                  <div className="btn-container">
                    <button type="submit">Submit</button>
                  </div>
                </form>
              )}
            </Formik>

            <div
              id="forgot-password"
              onClick={() => setAuthScreen("reset_password")}
            >
              Forgot password?
            </div>
          </div>
        </div>
        <div className="copyright">
          This website is fully subject to Belgian law on copyright, trademarks
          and, in general, on intellectual property. The Tractebel Belgium
          trademarks and logos (semi-figurative trademarks), including TRiceR
          and its logo, shown on the Website are registered trademarks. Any full
          or partial reproduction or representation, alone or in company with
          other elements, without the prior express written authorisation of
          Tractebel Belgium is strictly forbidden.
        </div>
      </>
    );
  } else if (authScreen === "new_password") {
    return (
      <>
        <a
          className="tricer-website-link top-left-clipped"
          alt="Official website"
          href="https://tractebel-engie.com/en/tricer"
        >
          <div className="website-text">Access official website</div>{" "}
          <img id="home-icon" src={homeLogo} alt="home" />
        </a>
        <div className="auth">
          <div className="auth-form-container-new-password">
            <div className="auth-title-container">
              <img
                className="tricer-logo"
                src={tricerLogo}
                alt="logo icefall"
              />
            </div>
            <div className="new-password-text">Please enter a new password</div>
            <Formik
              key="form2"
              initialValues={{
                newpass: "",
                newpassrepeat: "",
              }}
              validationSchema={Yup.object().shape({
                newpass: Yup.string()
                  .required("Password required")
                  .min(8, "Password should contain 8 characters")
                  .matches(
                    /(?=.*?[a-z])(?=.*?[0-9])/,
                    "Password must have at least 1 letter and 1 number"
                  )
                  .typeError("Must be a valid password"),
                newpassrepeat: Yup.string()
                  .required("Password required")
                  .oneOf([Yup.ref("newpass")], "Passwords do not match")
                  .typeError("Must be a valid password"),
              })}
              onSubmit={(values) => {
                setLoadingState(true);
                Amplify.Auth.completeNewPassword(currentUser, values.newpass)
                  .then((user) => {
                    setUser(user.username);
                    setLoadingState(false);
                    setModalStep([null]);
                    setUserIsLoggedIn(true);
                  })
                  .catch((err) => {
                    console.log(err);
                    setLoadingState(false);
                  });
              }}
            >
              {({ touched, errors, values, handleChange, handleSubmit }) => (
                <form key="new-password-form" onSubmit={handleSubmit}>
                  <TextField
                    className="input-field"
                    type="password"
                    name="newpass"
                    label={
                      (touched.password && errors.newpass) || "New password"
                    }
                    error={touched.newpass && errors.newpass !== undefined}
                    variant="outlined"
                    onChange={handleChange}
                    value={values.newpass}
                  />
                  <TextField
                    className="input-field"
                    type="password"
                    name="newpassrepeat"
                    label={
                      (touched.newpassrepeat && errors.newpassrepeat) ||
                      "Repeat password"
                    }
                    error={
                      touched.newpassrepeat &&
                      errors.newpassrepeat !== undefined
                    }
                    variant="outlined"
                    onChange={handleChange}
                    value={values.newpassrepeat}
                  />
                  <div className="btn-container">
                    <button
                      type="submit"
                      onClick={() =>
                        Amplify.Auth.completeNewPassword(
                          currentUser,
                          values.newpass
                        )
                      }
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className="copyright">
            This website is fully subject to Belgian law on copyright,
            trademarks and, in general, on intellectual property. The Tractebel
            Belgium trademarks and logos (semi-figurative trademarks), including
            TRiceR and its logo, shown on the Website are registered trademarks.
            Any full or partial reproduction or representation, alone or in
            company with other elements, without the prior express written
            authorisation of Tractebel Belgium is strictly forbidden.
          </div>
        </div>
      </>
    );
  } else if (authScreen === "reset_password") {
    return (
      <>
        <a
          className="tricer-website-link top-left-clipped"
          alt="Official website"
          href="https://tractebel-engie.com/en/tricer"
        >
          <div className="website-text">Access official website</div>{" "}
          <img id="home-icon" src={homeLogo} alt="home" />
        </a>
        <div className="auth">
          <div className="auth-form-container-reset-password">
            <div className="auth-title-container">
              <img
                className="tricer-logo"
                src={tricerLogo}
                alt="logo icefall"
              />
            </div>
            <Formik
              key="form3"
              initialValues={{
                email_reset: "",
              }}
              validationSchema={Yup.object().shape({
                email_reset: Yup.string()
                  .email("Valid email required")
                  .required("Email required")
                  .typeError("Invalid email"),
              })}
              onSubmit={(values) => {
                setCurrentEmail(values.email_reset);
                setLoadingState(true);
                Amplify.Auth.forgotPassword(values.email_reset)
                  .then((user) => {
                    setAuthScreen("reset_password_2");
                    setLoadingState(false);
                  })
                  .catch((err) => {
                    console.log(err);
                    setAuthScreen("login");
                    setLoadingState(false);
                  });
              }}
            >
              {({ touched, errors, values, handleChange, handleSubmit }) => (
                <form key="reset-password-form" onSubmit={handleSubmit}>
                  <TextField
                    className="input-field"
                    type="text"
                    name="email_reset"
                    label={
                      (touched.email_reset && errors.email_reset) || "Email"
                    }
                    error={
                      touched.email_reset && errors.email_reset !== undefined
                    }
                    autoComplete="email_reset"
                    variant="outlined"
                    onChange={handleChange}
                    value={values.email_reset}
                  />
                  <div className="btn-container">
                    <button type="submit">Submit</button>
                  </div>
                </form>
              )}
            </Formik>
            <div
              id="back-to-login"
              onClick={() => {
                setAuthScreen("login");
              }}
            >
              Back to login
            </div>
          </div>
        </div>
        <div className="copyright">
          This website is fully subject to Belgian law on copyright, trademarks
          and, in general, on intellectual property. The Tractebel Belgium
          trademarks and logos (semi-figurative trademarks), including TRiceR
          and its logo, shown on the Website are registered trademarks. Any full
          or partial reproduction or representation, alone or in company with
          other elements, without the prior express written authorisation of
          Tractebel Belgium is strictly forbidden.
        </div>
      </>
    );
  } else if (authScreen === "reset_password_2") {
    return (
      <>
        <a
          className="tricer-website-link top-left-clipped"
          alt="Official website"
          href="https://tractebel-engie.com/en/tricer"
        >
          <div className="website-text">Access official website</div>{" "}
          <img id="home-icon" src={homeLogo} alt="home" />
        </a>
        <div className="auth">
          <div className="auth-form-container-reset-password-2">
            <div className="auth-title-container">
              <img
                className="tricer-logo"
                src={tricerLogo}
                alt="logo icefall"
              />
            </div>
            <Formik
              key="form4"
              initialValues={{
                code: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                code: Yup.string()
                  .required("Confirmation code required")
                  .typeError("Invalid code"),
                password: Yup.string()
                  .required("Password required")
                  .typeError("Must be a valid password")
                  .min(8, "Password should contain 8 characters")
                  .matches(
                    /(?=.*?[a-z])(?=.*?[0-9])/,
                    "Password must have at least 1 letter and 1 number"
                  ),
              })}
              onSubmit={(values) => {
                setLoadingState(true);
                Amplify.Auth.forgotPasswordSubmit(
                  currentEmail,
                  values.code,
                  values.password
                )
                  .then((user) => {
                    console.log(user);
                    enqueueSnackbar("Succesfully updated password!", {
                      variant: "success",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    });
                    setAuthScreen("login");
                    setLoadingState(false);
                  })
                  .catch((err) => {
                    console.log(err);
                    enqueueSnackbar(err.message, {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    });
                    setLoadingState(false);
                  });
              }}
            >
              {({ touched, errors, values, handleChange, handleSubmit }) => (
                <form key="reset-password-2-form" onSubmit={handleSubmit}>
                  <TextField
                    className="input-field"
                    type="text"
                    name="code"
                    label={
                      (touched.code && errors.code) ||
                      "Confirmation code from email"
                    }
                    error={touched.code && errors.code !== undefined}
                    variant="outlined"
                    onChange={handleChange}
                    value={values.code}
                  />
                  <TextField
                    className="input-field"
                    type="password"
                    name="password"
                    label={
                      (touched.password && errors.password) || "New password"
                    }
                    error={touched.password && errors.password !== undefined}
                    variant="outlined"
                    onChange={handleChange}
                    value={values.password}
                  />
                  <div className="btn-container">
                    <button type="submit">Submit</button>
                  </div>
                </form>
              )}
            </Formik>
            <div
              id="back-to-login"
              onClick={() => {
                setAuthScreen("login");
              }}
            >
              Back to login
            </div>
          </div>
        </div>
        <div className="copyright">
          This website is fully subject to Belgian law on copyright, trademarks
          and, in general, on intellectual property. The Tractebel Belgium
          trademarks and logos (semi-figurative trademarks), including TRiceR
          and its logo, shown on the Website are registered trademarks. Any full
          or partial reproduction or representation, alone or in company with
          other elements, without the prior express written authorisation of
          Tractebel Belgium is strictly forbidden.
        </div>
      </>
    );
  }
}
