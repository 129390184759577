import React from 'react'
import './UserSupport.scss';
import questionIcon from "../../../../assets/icons/question.svg"
import userManual from "../../../../assets/files/usermanual.pdf"

export default function UserSupport() {
    return (
        <>
        <div id="user-support">
            <img id="question-mark" src={questionIcon} alt="support"></img>
            <div id="user-support-modal">
        If you encounter any issue with the application, please write us at : <a href="mailto:support.tricer@tractebel.engie.com">support.tricer@tractebel.engie.com</a>
        <br />
        <br />
        <a style={{textDecoration: "underline"}} href={userManual} download="Tricer_User_Manual.pdf" title="Download the user manual">Download User Manual</a>
        </div>
        </div>
        </>
    )
}
