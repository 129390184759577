import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField } from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";
import BelgiumMap from "./Map/BelgiumMap";
import WorldMap from "./Map/WorldMap";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import "./Step1b.scss";

export default function Step1b() {
  const { enqueueSnackbar } = useSnackbar();
  const setModalStep = useStoreActions((actions) => actions.setModalStep);
  const getLocationId = useStoreActions((actions) => actions.getLocationId);
  const addAOI = useStoreActions((actions) => actions.addAOI);
  const stepData = useStoreState((state) => state.stepData);
  const setStepData = useStoreActions((actions) => actions.setStepData);
  const lambert = useStoreState((state) => state.lambert);
  const setLambert = useStoreActions((actions) => actions.setLambert);
  const [latLongCoords, setLatLongCoords] = useState([]);
  const [lambertCoords, setLambertCoords] = useState([]);
  const [currentAreaName, setCurrentAreaName] = useState("");
  const currentItem = useStoreState((state) => state.currentItem);

  // Flemish gov users
  const govUsers = [
    {
      email: "hhilczer@outlook.com",
      turbines: [
        {
          name: "1",
          x: 144330,
          y: 207972,
        },
        {
          name: "2",
          x: 110164,
          y: 205375,
        },
        {
          name: "3",
          x: 147508,
          y: 218504,
        },
        {
          name: "4",
          x: 227735,
          y: 182037,
        },
        {
          name: "5",
          x: 149292,
          y: 196288,
        },
        {
          name: "6",
          x: 195722,
          y: 200602,
        },
        {
          name: "7",
          x: 36626,
          y: 172525,
        },
        {
          name: "8",
          x: 145516,
          y: 209247,
        },
        {
          name: "9",
          x: 202717,
          y: 155682,
        },
        {
          name: "10",
          x: 141657,
          y: 160054,
        },
      ],
    },
    {
      email: "karola.imbrechts@vlaanderen.be",
      turbines: [
        {
          name: "1",
          x: 144330,
          y: 207972,
        },
        {
          name: "2",
          x: 110164,
          y: 205375,
        },
        {
          name: "3",
          x: 147508,
          y: 218504,
        },
        {
          name: "4",
          x: 227735,
          y: 182037,
        },
        {
          name: "5",
          x: 149292,
          y: 196288,
        },
        {
          name: "6",
          x: 195722,
          y: 200602,
        },
        {
          name: "7",
          x: 36626,
          y: 172525,
        },
        {
          name: "8",
          x: 145516,
          y: 209247,
        },
        {
          name: "9",
          x: 202717,
          y: 155682,
        },
        {
          name: "10",
          x: 141657,
          y: 160054,
        },
      ],
    },
    {
      email: "david.rabaey@vlaanderen.be",
      turbines: [
        {
          name: "1",
          x: 144330,
          y: 207972,
        },
        {
          name: "2",
          x: 110164,
          y: 205375,
        },
        {
          name: "3",
          x: 147508,
          y: 218504,
        },
        {
          name: "4",
          x: 227735,
          y: 182037,
        },
        {
          name: "5",
          x: 149292,
          y: 196288,
        },
        {
          name: "6",
          x: 195722,
          y: 200602,
        },
        {
          name: "7",
          x: 36626,
          y: 172525,
        },
        {
          name: "8",
          x: 145516,
          y: 209247,
        },
        {
          name: "9",
          x: 202717,
          y: 155682,
        },
        {
          name: "10",
          x: 141657,
          y: 160054,
        },
      ],
    },
    {
      email: "nick.vertessen@vlaanderen.be",
      turbines: [
        {
          name: "1",
          x: 144330,
          y: 207972,
        },
        {
          name: "2",
          x: 110164,
          y: 205375,
        },
        {
          name: "3",
          x: 147508,
          y: 218504,
        },
        {
          name: "4",
          x: 227735,
          y: 182037,
        },
        {
          name: "5",
          x: 149292,
          y: 196288,
        },
        {
          name: "6",
          x: 195722,
          y: 200602,
        },
        {
          name: "7",
          x: 36626,
          y: 172525,
        },
        {
          name: "8",
          x: 145516,
          y: 209247,
        },
        {
          name: "9",
          x: 202717,
          y: 155682,
        },
        {
          name: "10",
          x: 141657,
          y: 160054,
        },
      ],
    },
    {
      email: "erwin.marien@vlaanderen.be",
      turbines: [
        {
          name: "1",
          x: 144330,
          y: 207972,
        },
        {
          name: "2",
          x: 110164,
          y: 205375,
        },
        {
          name: "3",
          x: 147508,
          y: 218504,
        },
        {
          name: "4",
          x: 227735,
          y: 182037,
        },
        {
          name: "5",
          x: 149292,
          y: 196288,
        },
        {
          name: "6",
          x: 195722,
          y: 200602,
        },
        {
          name: "7",
          x: 36626,
          y: 172525,
        },
        {
          name: "8",
          x: 145516,
          y: 209247,
        },
        {
          name: "9",
          x: 202717,
          y: 155682,
        },
        {
          name: "10",
          x: 141657,
          y: 160054,
        },
      ],
    },
    {
      email: "werner.vandendriessche@engie.com",
      turbines: [
        {
          name: "1",
          x: 144330,
          y: 207972,
        },
        {
          name: "2",
          x: 110164,
          y: 205375,
        },
        {
          name: "3",
          x: 147508,
          y: 218504,
        },
        {
          name: "4",
          x: 227735,
          y: 182037,
        },
        {
          name: "5",
          x: 149292,
          y: 196288,
        },
        {
          name: "6",
          x: 195722,
          y: 200602,
        },
        {
          name: "7",
          x: 36626,
          y: 172525,
        },
        {
          name: "8",
          x: 145516,
          y: 209247,
        },
        {
          name: "9",
          x: 202717,
          y: 155682,
        },
        {
          name: "10",
          x: 141657,
          y: 160054,
        },
      ],
    },
    {
      email: "kristof.gorissen@engie.com",
      turbines: [
        {
          name: "1",
          x: 144330,
          y: 207972,
        },
        {
          name: "2",
          x: 110164,
          y: 205375,
        },
        {
          name: "3",
          x: 147508,
          y: 218504,
        },
        {
          name: "4",
          x: 227735,
          y: 182037,
        },
        {
          name: "5",
          x: 149292,
          y: 196288,
        },
        {
          name: "6",
          x: 195722,
          y: 200602,
        },
        {
          name: "7",
          x: 36626,
          y: 172525,
        },
        {
          name: "8",
          x: 145516,
          y: 209247,
        },
        {
          name: "9",
          x: 202717,
          y: 155682,
        },
        {
          name: "10",
          x: 141657,
          y: 160054,
        },
      ],
    },
  ];

  useEffect(() => {
    if (!currentItem.aoiId) setLambert(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentItem.aoiId) setLambert(!currentItem.use_latlon);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItem]);

  useEffect(() => {
    if (currentItem.aoiId) return;
    if (lambert) {
      lambertCoords.length === 0 && setLambertCoords([149015, 172266]);
    } else {
      latLongCoords.length === 0 && setLatLongCoords([50.8609, 4.3548]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lambert]);

  useEffect(() => {
    if (stepData && stepData.area_location) {
      stepData.use_latlon ?
          setLatLongCoords([stepData.area_location[0], stepData.area_location[1]]) :
          setLambertCoords([stepData.area_location[0], stepData.area_location[1]]);
    } else {
      setLambertCoords([149015, 172266]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepData]);

  const validationShape = {
    area_name: Yup.string().required("Area Name")
        .test('no-forbidden-chars', 'The area name cannot contain /, ? or & characters', (value) => {
          return !/[/?&]/.test(value);
        })
        .typeError("Must be a string"),
    xInput: lambert
      ? Yup.number()
          .required("Lambert X")
          .min(14697.3, "Minimum is 155823.3")
          .max(297193.15, "Maximum is 297193.15")
          .typeError("Must be a number")
      : Yup.number()
          .required("Latitude")
          .min(-90, "Min is -90")
          .max(90, "Max is 90")
          .typeError("Must be a number"),
    yInput: lambert
      ? Yup.number()
          .required("Lambert Y")
          .min(20939.01, "Minimum is 155823")
          .max(246456.18, "Maximum is 297193")
          .typeError("Must be a number")
      : Yup.number()
          .required("Longitude")
          .min(-180, "Min is -180")
          .max(180, "Max is 180")
          .typeError("Must be a number"),
  };

  const validationSchema = Yup.object().shape(validationShape);

  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    initialValues: {
      area_name: (stepData && stepData.area_name) || currentAreaName,
      xInput:
        (stepData && stepData.area_location[0]) || ((lambert && lambertCoords) && lambertCoords[0]) || (latLongCoords && latLongCoords[0]) || "",
      yInput:
        (stepData && stepData.area_location[1]) || ((lambert && lambertCoords) && lambertCoords[1]) || (latLongCoords && latLongCoords[1]) || "",
    },
    validationSchema,
    onSubmit(data) {
      // Code for gov users
      Auth.currentUserInfo().then((userFromAMP) => {
        let currentGovUser = govUsers.filter((govUser) => {
          return govUser.email === userFromAMP.attributes.email;
        });
        if (currentGovUser.length > 0) {
          let found = false;
          currentGovUser[0].turbines.forEach((turb) => {
            if (
              turb.x === parseInt(data.xInput) &&
              turb.y === parseInt(data.yInput)
            ) {
              found = true;
              let obj = {
                area_name: data.area_name,
                area_location: [data.xInput, data.yInput],
                project_id: currentItem.projectId,
                use_latlon: !lambert,
              };
              // Passing a custom submit event data to the confirmation modal
              document.querySelector("#delete-modal-1b").style.display = "grid";
              document
                .querySelector("#delete-modal-1b-proceed")
                .addEventListener("click", () => {
                  let callData = addAOI(obj);
                  callData
                    .then((res) => {
                      if (res && res.err === true) {
                        enqueueSnackbar(res.message, {
                          variant: "error",
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        });
                      } else if (res && res.err === false) {
                        setStepData(null);
                        setModalStep([2]);
                      }
                    })
                    .catch((err) => console.log("ERR Found => ", err));
                  document.querySelector("#delete-modal-1b").style.display =
                    "none";
                  // cloning and replacing proceed button to remove events
                  let proceedButton = document.querySelector(
                    "#delete-modal-1b-proceed"
                  );
                  let newProceedButton = proceedButton.cloneNode(true);
                  proceedButton.parentNode.insertBefore(
                    newProceedButton,
                    proceedButton
                  );
                  proceedButton.parentNode.removeChild(proceedButton);
                });
            }
          });
          if (found === false) {
            enqueueSnackbar("You do not have access to those coordinates", {
              variant: "error",
              anchorOrigin: { horizontal: "left", vertical: "bottom" },
              autoHideDuration: 3000,
            });
          }
        } else {
          const area_location = lambert ? lambertCoords : latLongCoords
          let obj = {
            area_name: data.area_name,
            area_location: area_location,
            project_id: currentItem.projectId,
            use_latlon: !lambert,
          };
          // Passing a custom submit event data to the confirmation modal
          document.querySelector("#delete-modal-1b").style.display = "grid";
          document
            .querySelector("#delete-modal-1b-proceed")
            .addEventListener("click", () => {
              if (!lambert) {
                let getLocationIdCall = getLocationId({"lat": area_location[0], "lon": area_location[1]});
                getLocationIdCall.then((res) => {
                  if (res && res.err === true) {
                    enqueueSnackbar(res.message, {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    });
                  } else if (res && res.err === false) {
                    enqueueSnackbar(res.locationId, {
                      variant: "success",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    });
                  }
                });
              }

              let callData = addAOI(obj);
              callData
                .then((res) => {
                  if (res && res.err === true) {
                    enqueueSnackbar(res.message, {
                      variant: "error",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    });
                  } else if (res && res.err === false) {
                    setStepData(null);
                    setModalStep([2]);
                  }
                })
                .catch((err) => console.log("ERR Found => ", err));
              document.querySelector("#delete-modal-1b").style.display = "none";
              // cloning and replacing proceed button to remove events
              let proceedButton = document.querySelector(
                "#delete-modal-1b-proceed"
              );
              let newProceedButton = proceedButton.cloneNode(true);
              proceedButton.parentNode.insertBefore(
                newProceedButton,
                proceedButton
              );
              proceedButton.parentNode.removeChild(proceedButton);
            });
        }
      });

      // End code gov users
    },
    enableReinitialize: true,
  });

  return (
    <div className="step-1b-container">
      <div id="delete-modal-1b">
        <span id="delete-modal-1b-text">
          You are going to create a new Area of Interest. This action will be
          invoiced at the end of the current quarter. <br></br> <br></br>Are you
          willing to continue ?
        </span>
        <div id="delete-modal-1b-buttons">
          <div id="delete-modal-1b-proceed">Proceed</div>
          <div
            id="delete-modal-1b-cancel"
            onClick={() => {
              document.querySelector("#delete-modal-1b").style.display = "none";
              document.querySelector("#step-1b").style.pointerEvents = "all";
              // cloning and replacing proceed button to remove events
              let proceedButton = document.querySelector(
                "#delete-modal-1b-proceed"
              );
              let newProceedButton = proceedButton.cloneNode(true);
              proceedButton.parentNode.insertBefore(
                newProceedButton,
                proceedButton
              );
              proceedButton.parentNode.removeChild(proceedButton);
            }}
          >
            Cancel
          </div>
        </div>
      </div>
      <div
        id="step-1b"
        style={
          stepData && stepData.date
            ? { gridTemplateRows: "30px 22px auto" }
            : { gridTemplateRows: "46px auto" }
        }
      >
        <div
          className="modal-title"
          style={
            stepData && stepData.date
              ? {}
              : { borderBottom: "solid 1px lightgray" }
          }
        >
          Area of interest
        </div>
        {stepData && stepData.date && (
          <div className="date-text">
            created:{" "}
            {`${stepData.date.split("T")[0].split("-")[2]}-${
              stepData.date.split("T")[0].split("-")[1]
            }-${stepData.date.split("T")[0].split("-")[0]}`}
          </div>
        )}
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <TextField
              disabled={!!stepData}
              className="input-field"
              type="text"
              name="area_name"
              label={(touched.area_name && errors.area_name) || "Area Name"}
              error={touched.area_name && errors.area_name !== undefined}
              variant="outlined"
              onChange={(e) => {
                setCurrentAreaName(e.target.value);
                handleChange(e);
              }}
              value={values.area_name}
            />
            <div id="aoi-second-row-container">
              <div id="tog-cont">
                <div
                  onClick={() => {
                    if (currentItem.aoiId) return;
                    setLambert(!lambert);
                  }}
                  id="toggle-lambert"
                  className={lambert ? "off" : "on"}
                >
                  <div id="coordinates-belgium">🇧🇪</div>
                  <div id="coordinates-world">🌎</div>
                  <div id="slider"></div>
                </div>
              </div>
              <TextField
                disabled={!!stepData}
                className="input-field-lambert-x"
                id="x-input"
                type="text"
                name="xInput"
                label={
                  touched.xInput || errors.xInput
                    ? errors.xInput
                    : lambert
                    ? "Lambert X"
                    : "Latitude"
                }
                error={(touched.xInput && errors.xInput) !== undefined}
                variant="outlined"
                onChange={handleChange}
                value={values.xInput}
              />
              <TextField
                disabled={!!stepData}
                className="input-field-lambert-y"
                id="y-input"
                type="text"
                name="yInput"
                label={
                  touched.yInput || errors.yInput
                    ? errors.yInput
                    : lambert
                    ? "Lambert Y"
                    : "Longitude"
                }
                error={(touched.yInput && errors.yInput) !== undefined}
                variant="outlined"
                onChange={handleChange}
                value={values.yInput}
              />
              <div id="try-button">
                <button
                    className={(!errors.xInput || !errors.yInput) && "disabled"}
                  id="try-coords"
                  type="button"
                  disabled={!!errors.xInput || !!errors.yInput}
                  onClick={() => {
                    lambert ?
                        setLambertCoords([values.xInput, values.yInput]) :
                        setLatLongCoords([values.xInput, values.yInput])
                  }}
                >
                  Update map
                </button>
              </div>
              {stepData ? null : (
                <div className="submit-btn-container">
                  <button className="next-button" type="submit">
                    Save
                  </button>
                </div>
              )}
            </div>
            <div id="map-be">
              <BelgiumMap coords={lambert ? lambertCoords : latLongCoords} lambert={lambert} />
            </div>
            <div id="map-world">
              <WorldMap coords={lambert ? lambertCoords : latLongCoords} lambert={lambert} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
